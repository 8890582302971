<template>
  <view-container name="reset-password">
    <view-header>
      <custom-header
        :router-back="'/settings/security'"
        class="sub-page-settings__title"
      >
        <template v-slot:default>
          <svg-icon size="size20x20" :name="'icoArrowLeftBlue'" />
          Back
        </template>
      </custom-header>
    </view-header>

    <view-content>
      <div
        data-vue-name="PhysiologicalData"
        class="physiological-data layout-grid-2row-revers"
      >
        <div class="container-fluid">
          <div class="physiological-data__title sub-page-title-settings">
            Restore Password
          </div>
          <template v-if="!message">
            <form class="google-style-input" @submit.prevent="handleSubmit">
              <div class="group">
                <input type="text" name="full-name" v-model="email" />
                <label class="required-input">E-Mail</label>
                <span
                  v-if="errors.email"
                  class="danger google-style-input__description"
                  >{{ errors.email[0] }}</span
                >
                <span v-else class="google-style-input__description"
                  >This is the description area</span
                >
              </div>
              <button type="submit" class="reset btn-style1">Send</button>
            </form>
          </template>
          <template v-else>
            <div class="message sub-page-title-settings">
              {{ message }}
            </div>
          </template>
        </div>
      </div>
    </view-content>
  </view-container>
</template>
<script>
import { mapState } from 'vuex'
/* import { CoolSelect } from 'vue-cool-select' */

export default {
  name: 'RestorePassword',
  components: {},
  computed: {
    ...mapState({
      errors: ({ auth }) => auth.errors,
    }),
    isValidMail() {
      return this.validEmail(this.email)
    },
  },
  data() {
    return {
      email: '',
      message: '',
    }
  },
  methods: {
    handleSubmit() {
      if (!this.isValidMail) {
        return
      }
      const data = {
        email: this.email,
      }
      this.$store.dispatch('restorePassRequest', data).then(res => {
        this.message = res.message
      })
    },
    validEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    },
  },
}
</script>
<style scoped>
.danger {
  color: red !important;
}
.reset {
  width: 30%;
  padding: 0.5rem;
  margin: 0 auto;
}
.message {
  text-align: center;
}

.view-content {
  padding: 0 16px !important;
}

.view-content .container-fluid {
  padding: 0;
}
</style>
